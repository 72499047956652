import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import $ from "jquery";
import nft1 from '../assets/203.png';
import nft2 from '../assets/182.png';


$(function() {
    const scrollEffect = $('.scroll-effect-relic');
  
    $(window).on('scroll', function() {
      const containerTop = scrollEffect.offset().top;
      const containerBottom = containerTop + scrollEffect.outerHeight();
      const windowTop = $(window).scrollTop();
      const windowBottom = windowTop + $(window).height();
  
      if (windowBottom > containerTop) {
       scrollEffect.addClass('animate');
      } else {
       scrollEffect.removeClass('animate');
      }


      console.log("containerTop :" + containerTop);
      console.log("containerBottom :" + containerBottom);
      console.log("windowTop :" + windowTop);
      console.log("windowBottom :" + windowBottom);


    });
  });
  
const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class About extends Component {

    render() {
        return (

            <div class="boxWrap2StoryR">
                <div class="boxWrap2SubR">
                     <div class="scroll-effect-container">
                        <div class="scroll-effect-relic"><span class="storyH2">ABOUT</span></div>
                    </div>
                    <div class="about">
                        <div class="aboutPNew">

                            <p>Frog is a utility based meme token with a long-term vision. We believe in the power of community, collaboration, and transformation. Our mission is to revolutionize the crypto landscape by offering a token that not only holds immense value but also fosters a vibrant ecosystem of like-minded individuals.</p>
                       
                        </div>
 
                    </div>
                    <div class="facetoface"><img data-aos="fade-up" data-aos-duration="1000" src={nft2}/> <img data-aos="fade-up" data-aos-duration="1000"src={nft1}/></div>
                </div>
            </div>
        )
    }
}

export default About;

