import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import $ from "jquery";
import coin from '../assets/NFTHouse9_frog_face_embed_in_the_gold_coin_c5f3b6c3-9461-4548-968b-0c6401cf4a75-removebg-preview.png';

const token = () => {
    window.open("https://bscscan.com/address/0xDCD103Bc6D14829C39Afc9c10c9c373CE385D2C5");
}
const apiUrl2 = 'https://api.coingecko.com/api/v3/simple/token_price/binance-smart-chain?contract_addresses=0xDCD103Bc6D14829C39Afc9c10c9c373CE385D2C5&vs_currencies=usd&include_market_cap=true&include_24hr_change=true';

$(function () {
    const scrollEffect = $('.scroll-effect-tokenomics');

    $(window).on('scroll', function () {
        const containerTop = scrollEffect.offset().top;
        const containerBottom = containerTop + scrollEffect.outerHeight();
        const windowTop = $(window).scrollTop();
        const windowBottom = windowTop + $(window).height();

        if (windowBottom > containerTop) {
            scrollEffect.addClass('animate');
        } else {
            scrollEffect.removeClass('animate');
        }


        console.log("containerTop :" + containerTop);
        console.log("containerBottom :" + containerBottom);
        console.log("windowTop :" + windowTop);
        console.log("windowBottom :" + windowBottom);


    });
});

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Tokenomics extends Component {

    state = {
        _usdPrice: "",
        _usdMarketCap: "",
        _usd24hChange: ""
    }

    async componentDidMount() {

        // Make a GET request to the API
        fetch(apiUrl2)
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    console.error('Error fetching data from the API');
                }
            })
            .then(data => {
                // Check if the response contains the required data
                if (data && typeof data === 'object') {
                    const usdPrice = data["0xdcd103bc6d14829c39afc9c10c9c373ce385d2c5"].usd;
                    this.setState({ _usdPrice: usdPrice });

                    const usdMarketCap = data["0xdcd103bc6d14829c39afc9c10c9c373ce385d2c5"].usd_market_cap;
                    this.setState({ _usdMarketCap: usdMarketCap });

                    const usd24hChange = parseFloat(data["0xdcd103bc6d14829c39afc9c10c9c373ce385d2c5"].usd_24h_change).toFixed(2);
                    this.setState({ _usd24hChange: usd24hChange });


                    // Use the extracted data as needed
                    console.log("USD Price:", usdPrice);
                    console.log("USD Market Cap:", usdMarketCap);
                    console.log("USD 24h Change:", usd24hChange);
                } else {
                    console.error('Invalid data format from the API');
                }
            })
            .catch(error => {
                console.error('Network error:', error);
            });

    }

    render() {
        return (

            <div class="boxWrap2StoryT">
                <div class="boxWrap2Sub">
                    <div class="scroll-effect-container">
                        <div class="scroll-effect-tokenomics"><span class="storyH2">TOKENOMICS</span></div>
                    </div>
                    <div class="about">
                        <div class="aboutP">

                            <div class="aboutPDiv2" data-aos="fade-right" data-aos-duration="1000">Total Supply – <span class="hl">1,000,000,000</span></div>
                            <div class="aboutPDiv2" data-aos="fade-right" data-aos-duration="1000">Burned Amount – <span class="hl">83%</span></div>
                            <div class="aboutPDiv2" data-aos="fade-right" data-aos-duration="1000">Token Address – <span class="hl2" onClick={token}>0xDCD1...85D2C5</span></div>
                            <div class="aboutPDiv2" data-aos="fade-right" data-aos-duration="1000">Market Cap – <span class="hl">${this.state._usdMarketCap}</span></div>
                            <div class="aboutPDiv2" data-aos="fade-right" data-aos-duration="1000">Price –
                                <span style={{ color: parseFloat(this.state._usd24hChange) < 0 ? 'red' : '#1ee03e' }}>
                                    &nbsp;${this.state._usdPrice}&nbsp;
                                    ({this.state._usd24hChange}%)
                                </span>
                            </div>
                        </div>

                        <img class="zeus2" src={coin} />

                    </div>
                </div>
            </div>
        )
    }
}

export default Tokenomics;

